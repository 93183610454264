.custom-modal {
  width:80%
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.splash {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.icon-property, .btn-rotate i, .btn-magnify i, .btn-move-left i, .btn-move-right i {
  -webkit-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -moz-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  position: relative;
  display: inline-block; }
  
.btn-rotate:hover i, .btn-rotate:focus i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(53deg);
  -ms-transform: rotate(53deg);
  transform: rotate(53deg); }

/*
@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url(assets/fonts/oswald.woff) format('woff');
  }
*/